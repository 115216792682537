import React from 'react'

import { Container, Divider, Grid, Header, Image } from 'semantic-ui-react'

import projectsJson from 'data/projects.json'

import uvgImage from 'images/uvg.png'
import towerImage from 'images/the_tower.png'
import powerslideImage from 'images/powerslideweb.png'

import websiteImage from 'images/icons8-website-50.png'
import itchIoImage from 'images/itchio_logo_black.png'
import youtubeImage from 'images/icons8-youtube-48.png'
import githubImage from 'images/icons8-github-48-black.png'

type LinksT = {
    link: string,
    linkText: string,
    linkImage: string
}

type ProjectPropsT = {
    image: string
    name: string
    description: string
    links: Array<LinksT>
    id: number
}

const Project = (props: ProjectPropsT) => {

    const { image, name, description, links, id } = props
    const imageMap = {
        "uvg": uvgImage,
        "theTower": towerImage,
        'powerslide': powerslideImage
    }

    const linkMap = {
        "website": websiteImage,
        "itch": itchIoImage,
        "youtube": youtubeImage,
        "github": githubImage
    }

    return (
        <>
            <Grid.Column width={8} style={{
                display: 'flex',
                alignItems: 'center',
                padding: '96px'
            }}>
                <Container>
                    <Header as="h3">{ name }</Header>
                    <p>{ description }</p>
                    <div style={{ 
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        {links.map(l => (
                            <a href={l.link} style={{ padding: '0 16px'}}>
                                { /* @ts-ignore */ }
                                <Image src={linkMap[l.linkImage]} />
                            </a>
                        ))}
                    </div>
                </Container>
            </Grid.Column>
            <Grid.Column width={8} style={{
                display: 'flex',
                alignItems: 'center'
            }}>
                { /* @ts-ignore */ }
                <Image src={imageMap[image]} />
            </Grid.Column>
        </>
    )
}

const MainContent = () => {
    const projects = projectsJson.data

    return (
        <Container style={{ backgroundColor: '#F2F2F2', width: '100%' }}>
            <Container style={{
                width: '80%'
            }}>
                <Divider horizontal style={{ padding: '48px 0' }}>
                    <p style={{ 
                        fontSize: '32px',
                        color: '#555'
                    }}>
                        Projects
                    </p>
                </Divider>

                <Grid stackable>
                    {projects.map((p, index) =>  (
                        <Grid.Row style={{ marginBottom: '64px', flexDirection: index % 2 != 0 ? 'row-reverse' : 'row' }}>
                            <Project name={p.name} image={p.image} description={p.description} links={p.links} id={index} />
                        </Grid.Row>
                    ))}
                </Grid>
            </Container>
        </Container>

    )
}

export default MainContent