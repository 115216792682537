import React from "react";

import { Grid, Image, Header as SemanticHeader, Icon } from "semantic-ui-react";

import Container from '@mui/material/Container'

import splashImage from 'images/Splash-004.png'

import srLogo from 'images/SR-logo-white.png'
import tcLogo from 'images/TC-logo-white.png'
import nLogo from 'images/N-logo-white.png'

import githubLogo from 'images/icons8-github-48.png'
import linkedInLogo from 'images/icons8-linkedin-circled-50-white.png'

const Header = () => {
    console.log("Public Url: ", process.env)
    return (
        <Container style={{ 
            backgroundColor: '#007bbb',
            maxWidth: '100%',
            padding: 0
        }}>
            <Grid stackable>
                <Grid.Row style={{ 
                    paddingBottom: '0'
                }}>
                    <Grid.Column width={16}>
                           <Container>
                               <SemanticHeader as='h1' style={{
                                    color: 'white',
                                    fontSize: '128px',
                                    textAlign: 'left',
                                    margin: '32px 0'
                                }}>
                                    Hey.
                                </SemanticHeader>
                                <SemanticHeader as='h2' style={{
                                    color: 'white',
                                    fontSize: '64px',
                                    textAlign: 'left',
                                    margin: '32px 0'
                                }}>
                                    <div>I'm Andrew</div> <span style={{ color: '#00527d'}}>VR/Web</span> developer.
                                </SemanticHeader>
                                <Grid stackable style={{ marginTop: '64px' }}>
                                    <Grid.Row>
                                        <Grid.Column width={5}>
                                            <Container style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                <Image src={nLogo} size='small' style={{
                                                    width: '50px',
                                                    display: 'flex',
                                                    marginRight: '10px'
                                                }}/>
                                                <div style={{
                                                    display: "flex",
                                                    color: 'white',
                                                    fontSize: '32px',
                                                    letterSpacing: '0.05em'
                                                }}>
                                                    NORDSTROM
                                                </div>
                                            </Container>
                                            <div style={{
                                                color: 'white'
                                            }}>
                                                <div style={{
                                                    marginTop: '8px'
                                                }}>
                                                    <b>Software Engineer II</b>
                                                </div>
                                                <div>
                                                    <i>June 2020 - Present</i>
                                                </div>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column width={5}>
                                            <Container style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                <Image src={tcLogo} size='small' style={{
                                                    width: '50px',
                                                    display: 'flex',
                                                    marginRight: '10px'
                                                }}/>
                                                <div style={{
                                                    display: "flex",
                                                    color: 'white',
                                                    fontSize: '32px',
                                                    letterSpacing: '0.05em',
                                                }}>
                                                    TRUNK CLUB
                                                </div>
                                            </Container>
                                            <div style={{
                                                color: 'white'
                                            }}>
                                                <div style={{
                                                    marginTop: '8px'
                                                }}>
                                                    <b>Software Engineer I</b>
                                                </div>
                                                <div>
                                                    <i>August 2019 - June 2020</i>
                                                </div>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column width={5}>
                                            <Container style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                <Image src={srLogo} size='small' style={{
                                                    width: '50px',
                                                    display: 'flex',
                                                    marginRight: '10px'
                                                }}/>
                                                <div style={{
                                                    display: "flex",
                                                    color: 'white',
                                                    fontSize: '32px',
                                                    letterSpacing: 'normal',
                                                }}>
                                                    SHOPRUNNER
                                                </div>
                                            </Container>
                                            <div style={{
                                                color: 'white'
                                            }}>
                                                <div style={{
                                                    marginTop: '8px'
                                                }}>
                                                    <b>Support Engineer</b>
                                                </div>
                                                <div>
                                                    <i>April 2018 - August 2019</i>
                                                </div>
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                           </Container>
                           <Container style={{
                               display: 'flex',
                               marginTop: '64px',
                               marginBottom: '64px',
                           }}>
                               <a href='https://www.github.com/jump4r'>
                                   <Image src={githubLogo} style={{
                                        margin: '0 16px'
                                    }}/>
                               </a>
                               <a href="https://www.linkedin.com/in/andrew-morris-663973b6/">
                                   <Image src={linkedInLogo} style={{
                                        margin: '0 16px'
                                    }}/>
                               </a>           
                           </Container>
                    </Grid.Column> 
                </Grid.Row>
            </Grid>
        </Container>
    )
}

export default Header